import { Injectable } from '@angular/core';



@Injectable({
    providedIn: 'root'
})
export class CommonService {

    constructor() { }

    GetDateString(date: Date, dateStart: Boolean) {
        
        if (dateStart) {
            return date.getFullYear() + "-" +
                ("0" + (date.getMonth() + 1)).slice(-2) + "-" +
                ("0" + date.getDate()).slice(-2) + "T" +
                ("0" + "00").slice(-2) + ":" +
                ("0" + "00").slice(-2) + ":" +
                ("0" + "00").slice(-2) + "Z";
        } else {
            return date.getFullYear() + "-" +
                ("0" + (date.getMonth() + 1)).slice(-2) + "-" +
                ("0" + date.getDate()).slice(-2) + "T" +
                ("0" + "23").slice(-2) + ":" +
                ("0" + "59").slice(-2) + ":" +
                ("0" + "59").slice(-2) + "Z";
        }

    }

}