export class SyncDocumentModel {
    Id: number;
    RefIntNum: number;
    RefIntNumII: string;
    Clave: string;
    Consecutivo: string;
    Status: number;
    SysSync: number;
    CompanyId: number;
    SyncDocId: number;
    Details: string;
    DocDate: Date;
    SyncDate: Date;
    DocType: string;
    SysSyncStatus: number;
    SysSyncDetails: string;
    SysSyncDate: Date;
    LastAttemptSAP: Date;
    MaxQtyRowsFetch: number;
}

export class DocumentModelSearch {
    dateFrom: Date;
    dateTo: Date;
    dbCode: string;
    docType: string;
    docNum: number;
}