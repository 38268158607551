import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';

// Modelos
import {
  CompanyResponseModel, SearchedCompanyResponseModel,
  IdModelWrapper,
  RunType
} from '../models/index';

// Servicios
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';
import { CompaniesPaginatorResponseModel, CompaniesTypeAheadResponseModel } from '../models/responses';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {


  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private commonService: CommonService) { }


  getllCompanies() {
    const Session = JSON.parse(this.storage.getItem('Session'));

    const connId: number = Number(this.storage.getItem('DAOConn'));

    const params = new HttpParams()
      .set('ConnId', connId)

    return this.http.get<CompaniesTypeAheadResponseModel>(`api/Companies/GetAllCompanies`, { params });
  }


  // funcion que se comunica con el EndPoint para obtener la lista de compannias de la bd local
  getCompanies(StartPos: number, RowsReturned: number, DBCode: string, Active: string) {
    const Session = JSON.parse(this.storage.getItem('Session'));


    const connId: number = Number(this.storage.getItem('DAOConn'));

    const params = new HttpParams()
      .set('ConnId', connId)
      .set('StartPos', StartPos)
      .set('RowsReturned', RowsReturned)
      .set('DBCode', DBCode)
      .set('Active', Active);

    return this.http.get<CompaniesPaginatorResponseModel>(`api/Companies/GetCompanies`, { params});
  }


  // funcion que se comunica con el EndPoint para editar compannias
  EditCompany(companyForm: UntypedFormGroup, DBSyncPass: string, Id: number) {
    const Session = JSON.parse(this.storage.getItem('Session'));

    // modelo de compañia con los datos por actualizar
    const company = {
      Id,
      ConnectionId: companyForm.value.ConnectionId,
      DBCode: companyForm.value.DBCode,
      DBSyncId: companyForm.value.DBSyncId,
      DBSyncUser: companyForm.value.DBSyncUser,
      DBSyncPass: DBSyncPass,
      Active: companyForm.value.Active,
      SpecialFields: companyForm.value.SpecialFields,
      SBOConsecutivoFieldName: companyForm.value.SBOConsecutivoFieldName,
      ManualNum: companyForm.value.ManualNum,
      SBOClaveFieldName: companyForm.value.SBOClaveFieldName,
      SBOStatusFieldName: companyForm.value.SBOStatusFieldName,
      ActiveNfe: companyForm.value.ActiveNfe
    };
    const connId: number = Number(this.storage.getItem('DAOConn'));
    const body = {
      company,
      connId
    };
    return this.http.put<CompanyResponseModel>(`api/Companies/EditCompany`, body);
  }



  // funcion que se comunica con el EndPoint para guardar compannias nuevas
  saveCompany(companyForm: UntypedFormGroup) {
    const Session = JSON.parse(this.storage.getItem('Session'));

    const company = {
      ConnectionId: companyForm.value.ConnectionId,
      DBCode: companyForm.value.DBCode,
      DBSyncId: companyForm.value.DBSyncId,
      DBSyncUser: companyForm.value.DBSyncUser,
      DBSyncPass: companyForm.value.DBSyncPass,
      TokenExpires: this.commonService.GetDateString(new Date(), false),
      Active: companyForm.value.Active,
      SpecialFields: companyForm.value.SpecialFields,
      SBOIsUdf: companyForm.value.SBOIsUdf,
      SBOFieldName: companyForm.value.SBOFieldName,
      ManualNum: companyForm.value.ManualNum,
      SBOClaveUdf: companyForm.value.SBOClaveUdf,
      SBOClaveFieldName: companyForm.value.SBOClaveFieldName,
      SBOStatusUdf: companyForm.value.SBOStatusUdf,
      SBOStatusFieldName: companyForm.value.SBOStatusFieldName,
    };

    const connId: number = Number(this.storage.getItem('DAOConn'));
    const body = {
      company,
      connId
    };
    return this.http.post<CompanyResponseModel>(`api/Companies/CreateCompany`, body);
  }


}
