import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AlertsModule, AlertsService } from "@clavisco/alerts";

// Componentes
import { NavbarComponent } from './theme/components/navbar/navbar.component';

// RUTAS
import { APP_ROUTING } from './app.routes';
import { AuthService } from './core/services';
import { AuthGuard } from './core/guards/auth.guard';
import { DataGuard } from './core/guards/data-guard.service';
import { ErrorInterceptor } from './theme/utils/error.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LinkerService } from '@clavisco/linker';
import { ContainerMenuComponent } from './theme/components/menu/menu.component';
import { AppSettings } from './app.settings';
import { PagesComponent } from './pages/pages.component';
import { SharedModule } from './shared/shared.module';
import {UrlInterceptor} from "./theme/utils/url.interceptor";
import {TokenInterceptor} from "./theme/utils/token.interceptor";
import {RECAPTCHA_V3_SITE_KEY} from "ng-recaptcha";
import {Miscellaneous} from "@clavisco/core";


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    ContainerMenuComponent,
    PagesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    AlertsModule,
    APP_ROUTING,
  ],
  providers: [
    AppSettings,
    AuthService,
    AuthGuard,
    DataGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: 'LinkerService',
      useClass: LinkerService
    },
    {
      provide: 'AlertsService',
      useClass: AlertsService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Miscellaneous.Interceptors.HttpAlertInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: ''
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
