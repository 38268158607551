export const Alert = [
    {
        Id: 1,
        name: 'success'
    },
    {
        Id: 2,
        name: 'error'
    },
    {
        Id: 3,
        name: 'warning'
    },
    {
        Id: 4,
        name: 'info'
    }
];

export const Languajes = [
    {
        Name: 'ln_English',
    },
    {
        Name: 'ln_Spanish',
    }
];

export const DST = [
    {
        Name: 'dst_MSSQL2016',
    },
    {
        Name: 'dst_MSSQL2014',
    },
    {
        Name: 'dst_MSSQL2012',
    },
    {
        Name: 'dst_MSSQL2008',
    },
    {
        Name: 'dst_HANADB',
    }
];
