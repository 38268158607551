import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  // almacenamiento del browser
  private storage: any;
  // almacenamiento de la aplicacion
  private data: any;

  constructor(private router: Router) {
    this.storage = localStorage;
  }

  // obtener data almacenada en variable de almacenamiento de la aplicacion
  public getData(): any {
    return this.data;
  }

  // setea data en variable para almacenamiento de la aplicacion
  public setData(newData: any) {
    this.data = newData;
  }

  // guarda en el almacenamiento local la informacion de conexion a BD del DAO
  pushLocalStorageItem(item: any, itemName: string): void {
    this.storage.setItem(itemName, JSON.stringify(item));
  }

  // metodo para remover un item del almacenamiento local
  popLocalStorageItem(itemName: string): void {
    this.storage.removeItem(itemName);
  }

  // metodo para obtener un item del almacenamiento local
  getItem(itemName: string): string {
    const item = this.storage.getItem(itemName);
    return item;
  }

}
