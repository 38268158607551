import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Modelos
import { DAOConnectionModel, DAOConnectionsResponse, IdResponseModel } from '../models/index';

// Servicios
import { StorageService } from './storage.service';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DBModelSearch } from "../models/databases";
import { DBPaginatorResponse } from "../models/responses";

@Injectable({
  providedIn: 'root'
})
export class DataSourceService {

  constructor(private http: HttpClient,
    private storage: StorageService) {
  }


  // definir la fuente de datos del DAO
  SetDAOConnections(DAOConnection: DAOConnectionModel) {
    const Session = JSON.parse(this.storage.getItem('Session'));

    const body: DAOConnectionModel = {
      Id: DAOConnection.Id,
      DB: DAOConnection.DB,
      Name: DAOConnection.Name,
      Server: DAOConnection.Server,
      DBEngine: DAOConnection.DBEngine,
      DBUser: DAOConnection.DBUser,
      DBPass: DAOConnection.DBPass
    };

    return this.http.post<IdResponseModel>(`api/DAO/SetDAOConnection`, body)
      .pipe(map(conn => {
        // si se seteo la fuente de datos del DAO correctamente
        if (conn && conn.result) {
          // definimos en el almacenamiento del browser que la conexion del DAO ya fue definida
          this.storage.pushLocalStorageItem(DAOConnection.Id, 'DAOConn');
          this.storage.pushLocalStorageItem(DAOConnection.Name, 'DAOConnName');
        }
        return conn;
      }));
  }


  GetDAOConnections() {
    const Session = JSON.parse(this.storage.getItem('Session'));
    return this.http.get<DAOConnectionsResponse>(`api/DAO/GetDAOConnections`);
  }


  GetDatbasesPaged(dbModelSearch: DBModelSearch) {
    const Session = JSON.parse(this.storage.getItem('Session'));
    const body = dbModelSearch;
    return this.http.post<DBPaginatorResponse>(`api/DAO/GetPagedConnections`, body);
  }


}
