import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {StorageService} from "../../core/services";
import {ModalService} from "@clavisco/alerts";
import {LoginService} from "@clavisco/login";
import {Router} from "@angular/router";
import {Repository} from "@clavisco/core";
import {formatDate} from "@angular/common";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private storage: StorageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(request.url.includes('token'))
    {
      return next.handle(request);
    }

    let userToken = JSON.parse(this.storage.getItem('Session'));

    let clonedRequest: HttpRequest<unknown> = request.clone();

    if(userToken)
    {
      let headers = clonedRequest.headers.set('Authorization', `Bearer ${userToken.access_token}`);

      clonedRequest = clonedRequest.clone({headers});
    }

    let headers = clonedRequest.headers
      .set('cl-ui-request-timestamp', formatDate(new Date(), 'dd-MM-yyyy hh:mm:ss.SSS a', 'en'));

    clonedRequest = clonedRequest.clone({headers});

    // Valido que no tenga el header que indica si tiene archivos para no enviar el content type
    if(!clonedRequest.headers.has('Request-With-Files'))
    {
      let headers = clonedRequest.headers.set('Content-Type', 'application/json');
      clonedRequest = clonedRequest.clone({headers});
    }

    return next.handle(clonedRequest);
  }
}
