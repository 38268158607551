import {Repository, Structures} from '@clavisco/core';
import { environment } from 'src/environments/environment';

import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LinkerService } from '@clavisco/linker';
import { AuthService } from 'src/app/core/services';
import {AppSettings, Settings} from "./app.settings";
import {IToastGlobalConfiguration} from "@clavisco/alerts";
import {Login} from "@clavisco/login";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public settings: Settings;
  title = 'ClvsFEAdminUI';
  currentUser: any;
  ngOnInit(): void {
    Repository.Behavior.SetTokenConfiguration({
      token: 'Shared',
      setting: 'apiUrl',
      value: environment.ApiAppUrl
    });

    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.ALERTS,
      settings: {
        horizontalPosition: "center",
        verticalPosition: "bottom"
      } as IToastGlobalConfiguration
    });

    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.LOGN,
      setting: 'changePassword',
      value: {
        endpointToRequest: `${environment.ApiAppUrl}api/Users/ChangePassword`
      } as Login.Structures.IChangePasswordConfig
    })

    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.LOGN,
      setting: 'recoverPassword',
      value: {
        endpointToRequest: `${environment.ApiAppUrl}api/Users/ChangeRecoveryPassword`
      }  as Login.Structures.IChangePasswordConfig
    })

    Repository.Behavior.SetTokenConfiguration({
      token: Structures.Enums.TOKENS.LOGN,
      setting: 'sendRecoverPasswordEmail',
      value: {
        urlVariableToReplaceWithEmail: '#EMAIL#',
        endpointToRequest: `${environment.ApiAppUrl}api/Users/RecoveryPassword/?email=#EMAIL#`
      }
    })
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    @Inject('LinkerService') private linkerService: LinkerService,
    public appSettings: AppSettings,

  ) {
    this.settings = this.appSettings.settings;
    this.authService.currentUserSubject.subscribe(x => this.currentUser = x);
  }


}
