<nav class="navbar navbar-expand-md navbar-light mb-5 pt-3" *ngIf="currentUser">
    <a class="navbar-brand" [routerLink]="['/home']" (click)="RemoveActiveClass()">
        <!-- <img src="../../../../assets/images/icono-gris.svg" width="30" height="30" class="d-inline-block align-top" alt=""> Administración FE -->
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarOptions" aria-controls="navbarOptions" aria-expanded="false" aria-label="Toggle navigation">
        <!-- <span class="navbar-toggler-icon"></span> -->
    </button>
    <div class="collapse navbar-collapse justify-content-end pr-5" id="navbarOptions">
        <ul class="navbar-nav pr-5">
            <li class="nav-item mx-2">
                <a class="nav-link mynav-link" [routerLink]="['/sync-documents']" (click)="AddActiveClass($event)"><i class="fa fa-file" aria-hidden="true"></i><strong> Documentos</strong></a>
            </li>
            <li class="nav-item mx-2">
                <a class="nav-link mynav-link" [routerLink]="['/company-config']" (click)="AddActiveClass($event)"><i class="fa fa-industry" aria-hidden="true"></i><strong> Compañías</strong></a>
            </li>
            <li class="nav-item mx-2">
                <a class="nav-link mynav-link" [routerLink]="['/connection-config']" (click)="AddActiveClass($event)"><i class="fa fa-plug" aria-hidden="true"></i><strong> Conexiones</strong></a>
            </li>
            <li class="nav-item ml-5">
                <a class="nav-link mynav-link" [routerLink]="['/login']" (click)="LogOut()"><i class="fa fa-sign-out fa-lg" aria-hidden="true"></i><strong> Salir</strong></a>
            </li>
        </ul>
    </div>
</nav>