import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';


// Components
// Models
// Services
import { StorageService } from '../services/storage.service';
import {AlertsService, CLToastType} from "@clavisco/alerts";

@Injectable({
  providedIn: 'root'
})
export class DataGuard implements CanActivate {

  constructor(
    private router: Router,
    private storage: StorageService,
    private alertService: AlertsService
  ) {}

  canActivate() {
  // revisamos el almacenamiento del browser para ver si la conexion del DAO ya fue seteada
    const currConn = this.storage.getItem('DAOConn');
    if (currConn) {
        // authorized so return true
        return true;
    }
    this.alertService.Toast({ message: `Primero Debes Seleccionar la Base de Datos`, type: CLToastType.WARNING });
    this.router.navigate(['/home']);
    return false;
  }

}
