import { RouterModule, Routes } from '@angular/router';
// Componentes
import { PagesComponent } from './pages/pages.component';
import {AuthGuard} from "./core/guards/auth.guard";
import {DataGuard} from "./core/guards/data-guard.service";
import {ContainerMenuComponent} from "./theme/components/menu/menu.component";


export const APP_ROUTES: Routes = [
    {
        path: '',
        component: PagesComponent, children: [
          { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] },
          { path: 'company-config', loadChildren: () => import('./pages/company-config/company-config.module').then(m => m.CompanyConfigModule), canActivate: [DataGuard, AuthGuard] },
          { path: 'connection-config', loadChildren: () => import('./pages/connection-config/connection-config.module').then(m => m.ConnectionConfigModule), canActivate: [DataGuard, AuthGuard] },
          { path: 'sync-documents', loadChildren: () => import('./pages/sync-document-detail/sync-document-detail.module').then(m => m.SyncDocumentDetailModule), canActivate: [DataGuard, AuthGuard] },
          { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] }
        ]
      },
      { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
      { path: '**', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] }

    // { path: '**', component: NotFoundComponent }
];

// const APP_ROUTES: Routes = [
//     { path: '', pathMatch: 'full', redirectTo: 'home' },
//     {
//
//         path: '', component: ContainerMenuComponent, children: [
//           { path: 'company-config', component: CompanyConfigComponent, canActivate: [DataGuard, AuthGuard] },
//           { path: 'connection-config', component: ConnectionConfigComponent, canActivate: [DataGuard, AuthGuard] },
//           { path: 'sync-documents', component: SyncDocumentDetailComponent, canActivate: [DataGuard, AuthGuard] },
//           { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
//           { path: 'company-config', loadChildren: () => import('./pages/company-config/company-config.module').then(m => m.CompanyConfigModule), canActivate: [DataGuard, AuthGuard] },
//           { path: 'connection-config', loadChildren: () => import('./pages/connection-config/connection-config.module').then(m => m.ConnectionConfigModule), canActivate: [DataGuard, AuthGuard] },
//           { path: 'sync-documents', loadChildren: () => import('./pages/sync-document-detail/sync-document-detail.module').then(m => m.SyncDocumentDetailModule), canActivate: [DataGuard, AuthGuard] },
//           { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] }
//         ]
//     },
//
//   { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
//     // { path: 'login', component: LoginComponent },
//     { path: '**', pathMatch: 'full', redirectTo: 'home' },
//
// ];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES);
