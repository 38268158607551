import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';


// Modelos
import { SyncDocumentsResponseModel, DocumentModelSearch } from '../models/index';

// Servicios
import { StorageService } from './storage.service';
import { DocSearchModelWrapper } from '../models/model-wrapper';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SyncDocumentService {

    constructor(
        private http: HttpClient,
        private storage: StorageService
    ) { }


    GetSyncDocument(StartPos: number, RowsReturned: number, syncDocForm: UntypedFormGroup) {
        const Session = JSON.parse(this.storage.getItem('Session'));

        const docSearch: DocumentModelSearch = {
            dateFrom: syncDocForm.value.dateFrom,
            dateTo: syncDocForm.value.dateTo,
            dbCode: syncDocForm.value.dbCode,
            docType: syncDocForm.value.docType,
            docNum: Number(syncDocForm.value.DocNum)
        };

        const connId: number = Number(this.storage.getItem('DAOConn'));

        const body: DocSearchModelWrapper = {
            docSearch,
            connId,
            StartPos,
            RowsReturned
        };
        return this.http.post<SyncDocumentsResponseModel>(`api/SyncDocument/GetSyncDocuments`, body);
    }


}
