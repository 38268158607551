<cl-menu [Id]="MenuId" [Logo]="Logo" [Title]="Title" [User]="User" [Nodes]="MenuNodes">
  <div class="exclude">
      <mat-toolbar>
          <button mat-icon-button (click)="ToggleMenu()">
              <mat-icon>menu</mat-icon>
          </button>
      </mat-toolbar>
  </div>
      <router-outlet class="exclude"></router-outlet>
</cl-menu>

