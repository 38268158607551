import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown } from '@clavisco/linker';
import { IMenuNode } from '@clavisco/menu';
import { AuthService, StorageService } from 'src/app/core/services';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class ContainerMenuComponent implements OnInit {
  MenuId: string = 'menuId';

  Logo: string = '/assets/Logo-Clavisco.png';

  Title: string = 'Bienvenido a FE Console';

  User: string = '';

  Toggle: Boolean = true;

  MenuNodes: IMenuNode[] = [];


  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: []
  };


  constructor(
    private authService: AuthService,
    private router: Router,
    @Inject('LinkerService') private linkerService: LinkerService,
    private storage: StorageService
  ) {
    this.MenuNodes = [
      {
        Key: "home",
        Description: "BDs de Integrador",
        Icon: "account_tree",
        Route: "/home",
        Visible: true,
        Nodes: []
      },
      {
        Key: "documents",
        Description: "Documentos",
        Icon: "description",
        Route: "/sync-documents",
        Visible: true,
        Nodes: []
      },
      {
        Key: "companies",
        Description: "Compañías",
        Icon: "factory",
        Route: "/company-config",
        Visible: true,
        Nodes: []
      },
      {
        Key: "connections",
        Description: "Conexiones",
        Icon: "electrical_services",
        Route: "/connection-config",
        Visible: true,
        Nodes: []
      },
      {
        Key: "logout",
        Description: "Cerrar sesión",
        Icon: "logout",
        Route: "/login",
        Visible: true,
        Nodes: []
      },
    ]
  }


  ngOnInit(): void {
    if (JSON.parse(this.storage.getItem('Session')) != null){
      this.User = JSON.parse(this.storage.getItem('Session')).UserName;
    }
    const currConn = this.storage.getItem('DAOConnName');
    Register(this.MenuId, CL_CHANNEL.OUTPUT, this.ClickMenuOption, this.callbacks);
    this.linkerService.Flow()?.pipe(
      StepDown<CL_CHANNEL>(this.callbacks),
    ).subscribe({
      next: callback => Run(callback.Target, callback, this.callbacks.Callbacks),
      error: error => console.log(`Error:`, error)
    });
  }


  ClickMenuOption = (_event: ICLEvent): void => {
    const menuNode: IMenuNode = JSON.parse(_event.Data) as IMenuNode;
    switch (menuNode.Key) {
      case "logout":
        this.authService.logout();
    }
  }


  ToggleMenu(): void {
    this.Toggle = !this.Toggle;
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.DATA_LINE_1,
      Target: this.MenuId,
      Data: JSON.stringify(this.Toggle)
    } as ICLEvent);
  }


}
