export enum SAPStatus {
    Pendiente = 0, // no la ha enviado
    Actualizado = 1, // se hizo bien
    Procesando = 2, // no llego respuesta
    Error = 3 // dio error en SAP
}

export enum SyncStatus {
    Procesing = 0, // no la ha enviado
    Acepted = 1, // se hizo bien
    Reject = 2, // no llego respuesta
    Error = 3 // dio error en SAP
}

export enum DocTypes {
    Factura = 1, // Factura Electrónica
    ND = 2, // Nota de Débito
    NC = 3, // Nota de Crédito
    TE = 4, // Tiquete Electrónico
    Aceptacion = 5,
    AceptacionP = 6, // aceptacion parcial
    Rechazo = 7,
    FEC = 8, // Factura Electrónica Compra
    FEE = 9, // Factura Electrónica Exportación
}

export enum RunType {
    Manual = 1,
    Automatic = 2
}

export enum ExecutionType{
    Edit = 1,
    Create = 2,
}

export enum DBEngine {
  All = 'All',
  SQL = 'SQL',
  HANA = 'HANA',
}
