import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';


// Components
// Models
// Services
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,
    private router: Router,
    private localStorageService: StorageService) { }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const Session = this.localStorageService.getItem('Session');
    if (Session) {
      // authorized so return true
      this.authService.currentUserSubject.next(Session);
      return true;
    }
    return this.router.createUrlTree(['/login']);
  }


}
