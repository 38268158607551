import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// SERVICIOS
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public currentUserSubject: BehaviorSubject<any>;

  constructor(
    private storage: StorageService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(this.storage.getItem('Session')));
  }


  logout() {
    localStorage.removeItem('Session');
    localStorage.removeItem('DAOConn');
    localStorage.removeItem('DAOConnName');
    this.currentUserSubject.next(null);
  }


}
