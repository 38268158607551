import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

// Modelos
import {
  ConnectionsResponseModel, Connection,
  ConnModelWrapper
} from '../models/index';

// Servicios
import { StorageService } from './storage.service';
import { UntypedFormGroup } from '@angular/forms';
import { BaseResponse, ConnectionsPaginatorResponseModel, ConnectionsServerResponseModel, } from '../models/responses';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  constructor(private http: HttpClient,
    private storage: StorageService) { }


  GetAllConnections() {
    const Session = JSON.parse(this.storage.getItem('Session'));

    const connId: number = Number(this.storage.getItem('DAOConn'));

    const params = new HttpParams()
      .set('ConnId', connId);

    return this.http.get<ConnectionsResponseModel>(`api/Connection/GetAllConnections`, { params });
  }

  GetConnectionsServer() {
    const Session = JSON.parse(this.storage.getItem('Session'));

    const connId: number = Number(this.storage.getItem('DAOConn'));

    const params = new HttpParams()
      .set('ConnId', connId);

    return this.http.get<ConnectionsServerResponseModel>(`api/Connection/GetServerIdConnection`, { params });
  }



  GetConnections(StartPos: number, RowsReturned: number, Server: string, SapUser: string) {
    const Session = JSON.parse(this.storage.getItem('Session'));

    const connId: number = Number(this.storage.getItem('DAOConn'));

    const params = new HttpParams()
      .set('ConnId', connId)
      .set('StartPos', StartPos)
      .set('RowsReturned', RowsReturned)
      .set('Server', Server)
      // .set('DST', DST)
      .set('SapUser', SapUser);

    return this.http.get<ConnectionsPaginatorResponseModel>(`api/Connection/GetConnections`, { params});
  }


  // obtener el nombre de la conexion con el Id enviado por parametro
  getConectionName(connectionList: Connection[], conectionId: number): string {
    if (connectionList != null && connectionList !== undefined && connectionList.length > 0) {
      return connectionList.find(x => {
        return x.Id === conectionId;
      }).Server;
    } else {
      return '';
    }
  }


  // obtener un objeto conexion con el Id enviado por parametro
  getConnectionById(connectionList: Connection[], connectionId: number): Connection {
    return connectionList.find(x => {
      return x.Id === connectionId;
    });
  }


  EditConnection(connForm: UntypedFormGroup, SapPass: string, Id: number) {
    const Session = JSON.parse(this.storage.getItem('Session'));

    const conn: Connection = {
      Id,
      Server: connForm.value.Server,
      // LicenseServer: connForm.value.LicenseServer,
      //BoSuppLangs: connForm.value.Languaje,
      // DST: connForm.value.DST,
      SapUser: connForm.value.SapUser,
      SapPass: SapPass,
      // UseTrusted: connForm.value.UseTrusted
    };

    const connId: number = Number(this.storage.getItem('DAOConn'));
    const body: ConnModelWrapper = {
      conn,
      connId
    };
    return this.http.put<BaseResponse>(`api/Connection/EditConnection`, body);
  }


  CreateConnection(connForm: UntypedFormGroup) {
    const Session = JSON.parse(this.storage.getItem('Session'));

    const conn: Connection = {
      Id: 0,
      Server: connForm.value.Server,
      SapUser: connForm.value.SapUser,
      SapPass: connForm.value.SapPass,
    };
    const connId: number = Number(this.storage.getItem('DAOConn'));

    const body: ConnModelWrapper = {
      conn,
      connId
    };

    return this.http.post<BaseResponse>(`api/Connection/CreateConnection`, body);
  }


}
