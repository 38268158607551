import { Component, OnInit, ElementRef } from '@angular/core';

// SERVICES
import { StorageService, AuthService } from 'src/app/core/services';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  currentUser: any;

  constructor(
    private elRef: ElementRef,
    private storageService: StorageService,
    private authService: AuthService) {
    this.authService.currentUserSubject.subscribe(x => this.currentUser = x);
  }

  ngOnInit() {}

  RemoveActiveClass() {
    // obtenemos todos los navlinks con la clase active color
    const nativeElement: HTMLElement = this.elRef.nativeElement;
    const navLinks = nativeElement.getElementsByClassName('active-color');

    // le quitamos la clase, para indicar que ya no estan activos
    for (let i = 0; i < navLinks.length; i++) {
      navLinks.item(i).classList.remove('active-color');
    }
  }

  AddActiveClass(event: any) {
    this.RemoveActiveClass();

    // le añadimos la clase active al navlink que fue presionado
    event.target.classList.add('active-color');

  }

  verifyUserLogged() {
    return this.storageService.getItem('Session');
  }

  LogOut() {
    this.authService.logout();
  }

}
